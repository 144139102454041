.qr_frame{
  position: relative;
  overflow: hidden;
}
.overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 0%;
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.55);
  /* border: 2px solid #fff; */
  z-index: 1;
}
video{
  overflow: hidden;
}